import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = ({ location, data}) => {
  console.log(location, 'here', data)
  const page = data.allPrismicMenuLink.edges
  return (
  <Layout>
    <SEO title="404 - Page Not Found" description="404 - Page Not Found" location={location} />
    <div className="pt-32 lg:pt-36 mb-0 lg:mb-0" id="our-journey">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">Page Not Found</h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">Page Not Found</h1>
            </div>
            <div className="grid__half--secondary rte pt-4">
              <p>The requested page does not exist. Please confirm the URL, try again or use one of the links below to find the information you require:</p>
              <ul className="leading-relaxed">{page.map((item) => {
                return(
                  <li className="leading-relaxed"><Link className="no-underline" to={item.node.data.link.document[0].uid == 'home' ? "/" : item.node.data.link.document[0].uid}>{item.node.data.title}</Link></li>
                )
              })}
              </ul>
              <img src="https://images.prismic.io/puro001/f0297f06-3011-45a0-a1e9-bdbdbb92d268_DJI_0590.jpg?auto=compress,format" alt="Birds eye view of medical marijuana field in Marlborough, NZ."/>
            </div>
          </div>
        </div>
      </div>
  </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}


export const NotFoundPageQuery = graphql`
query {
  allPrismicMenuLink(sort: { fields: [data___order], order: ASC }) {
    totalCount
    edges {
      node {
        data {
          title
          hide_on_desktop_header
          link {
            isBroken
            document {
              ... on PrismicAbout {
                uid
              }
              ... on PrismicPlans {
                uid
              }
              ... on PrismicFaqs {
                uid
              }
              ... on PrismicJoinUs {
                uid
              }
              ... on PrismicNewsIndex {
                uid
              }
              ... on PrismicContact {
                uid
              }
              ... on PrismicImageGallery {
                uid
              }
              ... on PrismicHome {
                uid
              }
              


            }
          }
          child_menu_items{
            child_title{
              text
            }
            child_order
            child_link {
              url
            }
            child_scroll_to
          }
        }
      }
    }
  }
}
`

export default NotFoundPage
